import React from "react";

import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

interface HeaderProps {
  handleOpenMobileNav: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleOpenMobileNav }) => {
  return (
    <header className="border-b border-gray-100 border-solid">
      <div className="container">
        <div className="flex items-center justify-between py-2">
          <div>
            <Link to="/">
              <img
                src="/logo.png"
                alt="Noda Painting"
                width={128}
                height={103}
              />
            </Link>
          </div>
          <div>
            <ul className="hidden md:flex items-center">
              <MyListItem to="/about/">About</MyListItem>
              <MyListItem to="/residential/">Residential</MyListItem>
              <MyListItem to="/commercial/">Commercial</MyListItem>
              <MyListItem to="/projects/">Projects</MyListItem>
              <MyListItem to="/contact/">Contact</MyListItem>
            </ul>
            <div className="md:hidden">
              <IconButton
                aria-label="Navigation bar"
                onClick={handleOpenMobileNav}
                className="text-text-heading"
              >
                <Menu />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

interface MyListItemProps {
  to: string;
  partiallyActive?: boolean;
}

const MyListItem: React.FC<MyListItemProps> = ({
  to,
  partiallyActive,
  children,
}) => {
  return (
    <li className="text-base text-text-heading font-display px-1 mx-2">
      <Link to={to} activeClassName="font-medium">
        {children}
      </Link>
    </li>
  );
};
