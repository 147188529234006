import React from "react";

import { Instagram } from "@material-ui/icons";

import contactInfo from "../../content/settings/contact_info.json";
import { formatPhoneNumber } from "../../utils/formatPhoneNumber";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  return (
    <footer className="pt-[50px] bg-primary-darker">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex lg:justify-between gap-8 ">
          <div className="w-full lg:w-3/12">
            <img
              src="/logo-dark.png"
              alt="Noda Painting"
              width={128 * 0.8}
              height={103 * 0.8}
            />
            {/* <StaticImage
              src="../../images/logo-dark.png"
              alt="Noda Painting"
              layout="fixed"
              width={128 * 0.8}
              height={103 * 0.8}
            /> */}

            <p className="text-primary-light text-base">
              Providing the Charlotte area with professional residential and
              commercial painting services for over 15 years.
            </p>
          </div>
          <div className="text-primary-light w-full lg:w-3/12">
            <h3 className="text-primary-light text-xl font-bold">Services</h3>
            <ul>
              <MyListItem to="/kitchen-cabinets/">Kitchen cabinets</MyListItem>
              <MyListItem to="/residential/">Residential</MyListItem>
              <MyListItem to="/commercial/">Commercial</MyListItem>
            </ul>
          </div>
          <div className="text-primary-light w-full lg:w-3/12">
            <h3 className="text-primary-light text-xl font-bold">
              Quick links
            </h3>
            <ul>
              <MyListItem to="/about/">About</MyListItem>
              <MyListItem to="/projects/">Projects</MyListItem>
              <MyListItem to="/contact/">Get a quote</MyListItem>
            </ul>
          </div>
          <div className="text-primary-light w-full lg:w-max">
            <h3 className="text-primary-light text-xl font-bold">Contact</h3>
            <ul>
              <ContactListItem>{contactInfo.address.street}</ContactListItem>
              <ContactListItem>
                {contactInfo.address.city}, {contactInfo.address.zipCode},{" "}
                {contactInfo.address.state}
              </ContactListItem>
              <ContactListItem>
                <a href={`tel:${contactInfo.phone}`}>
                  {formatPhoneNumber(contactInfo.phone)}
                </a>
              </ContactListItem>
              <ContactListItem>
                <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
              </ContactListItem>
              <ContactListItem>
                <a
                  href={contactInfo.socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Instagram />
                </a>
              </ContactListItem>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-black text-gray-600 text-center text-xs py-4 mt-[50px]">
        <span>© Copyright 2021. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;

interface MyListItemProps {
  to: string;
  partiallyActive?: boolean;
}

const MyListItem: React.FC<MyListItemProps> = ({
  to,
  partiallyActive,
  children,
}) => {
  return (
    <li className="py-2 my-2 border-b border-primary-light/50 capitalize">
      <Link to={to}>{children}</Link>
    </li>
  );
};

interface ContactListItemProps {}

const ContactListItem: React.FC<ContactListItemProps> = ({ children }) => {
  return <li className="mb-1 last:mb-0">{children}</li>;
};
