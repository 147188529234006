import React, { useState } from "react";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";

import "@fontsource/inter";
import "@fontsource/source-sans-pro";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";

import "../styles/global.css";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import MobileDrawer from "./layout/MobileDrawer";

interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [showMobileNavDrawer, setShowMobileNavDrawer] = useState(false);

  const handleMobileNavClose = () => {
    setShowMobileNavDrawer(false);
  };

  const handleMobileNavOpen = () => {
    setShowMobileNavDrawer(true);
  };
  return (
    <ThemeProvider theme={theme}>
      <div id="app">
        <div
          className={`relative transition-transform duration-300 ${
            showMobileNavDrawer ? "translate-x-[-250px]" : ""
          }`}
        >
          {showMobileNavDrawer ? (
            <div
              className="fixed bg-black/30 inset-0 w-full h-full z-[100]"
              onClick={handleMobileNavClose}
            />
          ) : null}

          <Header handleOpenMobileNav={handleMobileNavOpen} />

          <main className={`overflow-hidden`}>{children}</main>
          <Footer />
        </div>

        <MobileDrawer
          showMobileNav={showMobileNavDrawer}
          handleCloseMobileNav={handleMobileNavClose}
        />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
