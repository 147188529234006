import { createTheme } from "@material-ui/core";

const tailwindConfig = require("../../tailwind.config.js");

const theme = createTheme({
  typography: {
    fontFamily: tailwindConfig.theme.fontFamily.sans,
  },
  palette: {
    primary: {
      light: "#e2e3e4",
      main: "#1b1c1c",
      dark: "#080808",
    },
  },
});

export default theme;
