/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import contactInfo from "../content/settings/contact_info.json";

interface SEOProps {
  description?: string;
  lang?: string;
  meta?: any;
  title?: string;
  slug?: string;
}

const SEO: React.FC<SEOProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title = "",
  slug = "",
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  const siteUrl = site.siteMetadata.siteUrl;

  const currentUrl = `${siteUrl}${slug}`;

  const canonicalUrl =
    currentUrl.slice(-1) === "/" ? currentUrl : `${currentUrl}/`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      link={
        canonicalUrl
          ? [
              {
                rel: "canonical",
                href: canonicalUrl,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          propert: `og:locale`,
          content: lang,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": `${siteUrl}/#website`,
              url: siteUrl,
              name: defaultTitle,
              description: `${site.siteMetadata.description}`,
              inLanguage: "en-US",
            },
            {
              "@type": "WebPage",
              "@id": `${canonicalUrl}#webpage`,
              url: `${canonicalUrl}`,
              name: `${title}`,
              isPartOf: { "@id": `${siteUrl}/#website` },
              description: `${metaDescription}`,
              inLanguage: "en-US",
            },
          ],
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          name: `${contactInfo.companyName}`,
          url: `${siteUrl}`,
          description: `${site.siteMetadata.description}`,
          telephone: `${contactInfo.phone}`,
          email: `${contactInfo.email}`,
          openingHours: ["Mo-Fr 9:00-18:30", "Sa 10:00-16:00"],
          logo: `${siteUrl}/images/logo.png`,
          image: `${siteUrl}/images/logo.png`,
          address: {
            "@type": "PostalAddress",
            streetAddress: `${contactInfo.address.street}`,
            addressLocality: `${contactInfo.address.city}`,
            addressRegion: `${contactInfo.address.state}`,
            addressCountry: "USA",
            postalCode: `${contactInfo.address.zipCode}`,
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: 43.21503155249347,
            longitude: -80.979213,
          },
          hasMap: "",
          sameAs: Object.values(contactInfo.socialMedia).map(
            (account) => account
          ),
          areaServed: [
            {
              "@type": "State",
              name: "North Carolina",
              "@id": "https://en.wikipedia.org/wiki/North_Carolina",
            },
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;
