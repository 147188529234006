import { Link } from "gatsby";
import React from "react";

interface MobileDrawerProps {
  showMobileNav: boolean;
  handleCloseMobileNav: () => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ showMobileNav }) => {
  return (
    <div
      className={`fixed w-[250px] h-full top-0 right-0 bg-primary-dark transition-all duration-300 translate-x-[250px] `}
      style={{ transform: showMobileNav ? "translate(0, 0)" : "" }}
    >
      <div className="px-8 py-16 h-full overflow-y-scroll ">
        <ul>
          <MyListItem to="/">Home</MyListItem>
          <MyListItem to="/about/">About</MyListItem>
          <MyListItem to="/residential/">Residential</MyListItem>
          <MyListItem to="/commercial/">Commercial</MyListItem>
          <MyListItem to="/projects/">Projects</MyListItem>
          <MyListItem to="/contact/">Contact</MyListItem>
        </ul>
      </div>
    </div>
  );
};

export default MobileDrawer;

interface MyListItemProps {
  to: string;
  partiallyActive?: boolean;
}

const MyListItem: React.FC<MyListItemProps> = ({
  to,
  partiallyActive,
  children,
}) => {
  return (
    <li className="py-4 text-lg text-primary-light border-b border-primary-light">
      <Link to={to}>{children}</Link>
    </li>
  );
};
