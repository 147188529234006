const colors = require("tailwindcss/colors");
const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
  mode: "jit",
  important: "#app",
  purge: ["./src/**/*.{js,jsx,ts,tsx}"],
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      display: ["Inter", ...defaultTheme.fontFamily.sans],
      body: ["Source Sans Pro", ...defaultTheme.fontFamily.sans],
    },
    fontSize: {
      tiny: "0.64rem",
      xs: ".8rem",
      sm: ".875rem",
      base: "1rem",
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.563rem",
      "3xl": "1.953rem",
      "4xl": "2.441rem",
      "5xl": "3.052rem",
      "6xl": "3.815rem",
      "7xl": "4.768rem",
    },
    extend: {
      colors: {
        gray: colors.gray,
        primary: {
          light: "#e2e3e4",
          main: "#1b1c1c",
          dark: "#080808",
          darker: "#020202",
        },
        text: {
          main: "#555858",
          heading: "#1b1c1c",
        },
        gridTemplateRows: {
          8: "repeat(8, minmax(0, 1fr))",
        },
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
